import './Home.css';
import React, { Component } from 'react';
import ShortenForm from '../components/ShortenForm';
import Copyright from '../components/Copyright';

export default function Home(){
    return(
        <div className='Home'>
            <h1 className='header'>Small URL</h1>
            <ShortenForm/>
            <Copyright/>
        </div>
    )
}