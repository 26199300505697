import React, { Component, useState } from 'react';
import './ShortenForm.css';
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";

export default function ShortenForm(){
    const [url, setUrl] = React.useState(false)
    const urlText = React.useRef()
    const fullUrl = React.useRef()

    const copyToClipboard = () => {
        let copyText = urlText.current;
    // Adding text value to clipboard using copy function
        let isCopy = copy(copyText);
        if (isCopy) {
            toast.success("Copied to Clipboard");
          }
    }

    const addUrl = async () => {
        var jsonData = {
            url : fullUrl.current.value
        }
        axios.post("https://www.smur1.xyz/shorten",
      {  body : jsonData}
        ).then((response) => {
            setUrl(true)
           urlText.current = response.data;
         
          });
        
        };

        function shortenurl(){
            addUrl();
       
        }

        function reset(){
            setUrl(false)
            urlText.current = ""
            fullUrl.current.value = ""
        }
        
    React.useEffect(()=>{

    },[])

    return(
        <div className='ShortenForm'>
            <div className='inputs'>
                <div className='input-group'>
                    
                <input className='ShortenForm-input' placeholder='Enter Your Long URL'  type='text' ref={fullUrl}/>
                
                {url ? <></>:
                <button onClick={shortenurl} >Shorten</button>
                }
                
                </div>
       
           {url ? <div>
            <div className='input-group'> <input  className='ShortenForm-input' disabled  type='text' value={urlText.current}/><button onClick={copyToClipboard}  >Copy</button> </div>
            <div className='input-group'>
           <button onClick={reset} className='button-long'>
                Shorten Another
            </button>
           </div>
            </div> : <></>}
           
           
           
            </div>
            
            
        </div>
    )
}