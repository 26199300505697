import Home from './pages/Home';
import { Route, Routes , BrowserRouter} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path='/' element={<Home/>}/>
      </Routes>
      </BrowserRouter>
      
      
    </div>
  );
}

export default App;
